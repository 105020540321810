@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap');

@layer base {
    body {
        @apply font-[Heebo];
        @apply text-white;
    }
    li {
        @apply px-4;
        @apply cursor-pointer;
    }
    .ham-on, .ham-off {
        @apply cursor-pointer;
    }
}

.content-div {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 250px;
}

.content-div:hover {
    background-image: linear-gradient(
        to right,
        #c3073fc2,
        #950740c2
    )!important;
}

